import React from 'react';
import styled from 'styled-components';
import leftBottom from '../assets/img/left-bottom.svg';
import leftTop from '../assets/img/left-top.svg';
import rightBottom from '../assets/img/right-bottom.svg';
import logoBackground from '../assets/img/logo-background.svg';

const LayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #000;
  overflow: hidden;
`;

const BackgroundElement = styled.img`
  position: fixed;
  z-index: 0;
  pointer-events: none;
`;

const LogoBackground = styled(BackgroundElement)`
height: 35vh;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
`;

const LeftTop = styled(BackgroundElement)`
  height: 45vh;  
  left: 0;
  top: 10%;
`;

const LeftBottom = styled(BackgroundElement)`
  height: 20vh;
  left: 0;
  bottom: 0;
`;

const RightBottom = styled(BackgroundElement)`
  height: 50vh;
  right: 0;
  bottom: 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <LogoBackground src={logoBackground} alt="Logo Background" />
      <LeftTop src={leftTop} alt="Left Top Decoration" />
      <LeftBottom src={leftBottom} alt="Left Bottom Decoration" />
      <RightBottom src={rightBottom} alt="Right Bottom Decoration" />
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </LayoutContainer>
  );
};

export default Layout; 