import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { EVENT_CONFIG } from './config/eventConfig';
import 'react-toastify/dist/ReactToastify.css';
import Form from './components/Form';
import RealTimeDisplay from './components/RealTimeDisplay';
import WinnerSelection from './components/WinnerSelection';
import axios from 'axios';  
import IntroSection from './components/IntroSection';
import Layout from './components/Layout';
import CountdownTimer from './components/CountdownTimer';
import { AuthProvider, useAuth } from './context/AuthContext';
import { useMediaQuery } from 'react-responsive';
import { API_BASE_URL } from './config/apiConfig';
import { socket } from './socket';
import WebkeyzLogo from './components/WebkeyzLogo';
import Cookies from 'js-cookie';

const StyledApp = styled(motion.div)`
  min-height: 100vh;
  padding: 0 20px;
  
  .main-container {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

// Add this styled component for hidden toast
const HiddenToastContainer = styled(ToastContainer)`
  .Toastify__toast-container,
  .Toastify__toast,
  .Toastify__toast-body {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    position: absolute !important;
    pointer-events: none !important;
  }
`;

function App() {
  const { isAdmin } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [submissions, setSubmissions] = useState([]);
  const [eventState, setEventState] = useState({
    isSubmissionOpen: false,
    winnersSelected: false,
    remainingTime: 0,
    hasSubmissions: false,
    endTime: null,
    isInitialized: false
  });

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [eventResponse, submissionsResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/event/status`),
          axios.get(`${API_BASE_URL}/api/submissions`)
        ]);

        const hasAnySubmissions = submissionsResponse.data.length > 0;
        
        setSubmissions(submissionsResponse.data);
        setEventState(prev => ({
          ...eventResponse.data,
          hasSubmissions: hasAnySubmissions || prev.hasSubmissions,
          isInitialized: true
        }));
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
        toast.error('Failed to connect to server');
        setEventState(prev => ({ ...prev, isInitialized: true }));
      }
    };

    fetchInitialData();
  }, []);

  // Socket.io event listeners
  useEffect(() => {
    socket.on('submissionWindowOpened', (data) => {
      toast.success(data.message);
      setEventState(prev => ({
        ...prev,
        isSubmissionOpen: true,
        endTime: data.endTime
      }));
    });

    socket.on('submissionWindowClosed', (data) => {
      toast.info(data.message);
      setEventState(prev => ({
        ...prev,
        isSubmissionOpen: false,
        hasSubmissions: true,
        endTime: null,
        winnersSelected: false
      }));
      
      // Fetch latest submissions when window closes
      fetchSubmissions();
    });

    socket.on('newSubmission', (submission) => {
      setSubmissions(prev => {
        const exists = prev.some(sub => sub._id === submission._id);
        if (exists) return prev;
        return [...prev, submission];
      });
      
      setEventState(prev => ({
        ...prev,
        hasSubmissions: true
      }));
    });

    socket.on('winnersSelected', (data) => {
      toast.success(data.message);
      setEventState(prev => ({
        ...prev,
        winnersSelected: true,
        winners: data.winners
      }));
    });

    return () => {
      socket.off('submissionWindowOpened');
      socket.off('submissionWindowClosed');
      socket.off('newSubmission');
      socket.off('winnersSelected');
    };
  }, []);

  // Add new socket listener for event reset
  useEffect(() => {
    socket.on('eventReset', () => {
      setEventState({
        isSubmissionOpen: false,
        winnersSelected: false,
        remainingTime: 0,
        hasSubmissions: false
      });
      setSubmissions([]);
    });

    return () => {
      socket.off('eventReset');
    };
  }, []);

  // Timer effect to update remaining time
  useEffect(() => {
    if (!eventState.isSubmissionOpen) return;

    const timer = setInterval(() => {
      const now = new Date();
      const endTime = new Date(eventState.endTime);
      const remaining = Math.max(0, endTime - now);

      setEventState(prev => ({
        ...prev,
        remainingTime: remaining
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [eventState.isSubmissionOpen, eventState.endTime]);

  // Add this useEffect for socket reconnection handling
  useEffect(() => {
    const handleReconnect = async () => {
        try {
            // Refetch data when socket reconnects
            const [eventResponse, submissionsResponse] = await Promise.all([
                axios.get(`${API_BASE_URL}/api/event/status`),
                axios.get(`${API_BASE_URL}/api/submissions`)
            ]);

            setEventState(eventResponse.data);
            setSubmissions(submissionsResponse.data);
        } catch (error) {
            console.error('Failed to fetch data after reconnection:', error);
        }
    };

    socket.on('reconnect', handleReconnect);

    return () => {
        socket.off('reconnect', handleReconnect);
    };
  }, []);

  // Add this function to fetch submissions
  const fetchSubmissions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/submissions`);
      setSubmissions(response.data);
    } catch (error) {
      console.error('Failed to fetch submissions:', error);
      toast.error('Failed to fetch submissions');
    }
  };

  return (
    <AuthProvider>
      <Layout>
        <StyledApp>
          <HiddenToastContainer position="bottom-right" />
          <div className="main-container">
            {!eventState.isInitialized ? (
              // Loading state
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                style={{ textAlign: 'center', padding: '2rem' }}
              >
                {/* Loading... */}
              </motion.div>
            ) : eventState.isSubmissionOpen ? (
              <AnimatePresence>
                <motion.div>
                  <WebkeyzLogo />
                  <CountdownTimer timeRemaining={eventState.remainingTime} />
                  {(!isAdmin && Cookies.get('isAdmin') !== 'true') && <Form />}
                  {!isMobile && <RealTimeDisplay className="real-time-display" />}
                </motion.div>
              </AnimatePresence>
            ) : (eventState.hasSubmissions || submissions.length > 0) ? (
              <WinnerSelection
                submissions={submissions}
                maxWinners={EVENT_CONFIG.maxWinners}
                winners={eventState.winners}
                isAdminProp={isAdmin || Cookies.get('isAdmin') === 'true'}
              />
            ) : (
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <IntroSection />
              </motion.div>
            )}
          </div>
        </StyledApp>
      </Layout>
    </AuthProvider>
  );
}

export default App;
