import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { EVENT_CONFIG } from '../config/eventConfig';
import Cookies from 'js-cookie';
import webkeyzLogo from '../../src/assets/img/webkeyz-logo.svg';
import marketingPlusLogo from '../../src/assets/img/marketingplus2024-logo.png';
import { API_BASE_URL } from '../config/apiConfig';

const IntroContainer = styled(motion.div)`
  text-align: center;
  color: white;
  max-width: 1000px;
  margin: 0 auto;
  padding: 120px;
  border-radius: 30px;

  @media (max-width: 768px) {
    padding: 30px 20px;
    border-radius: 20px;
  }

  .main-heading {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 6.5rem;
    font-weight: 900;
    color: white;
    line-height: 1.1;

    .highlight {
      font-size: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      font-size: 3.5rem;
      margin-bottom: 50px;

      .highlight {
        font-size: 2.5rem;
      }
    }

  }

  .sub-heading {
    font-size: 3.5rem;
    margin-bottom: 3rem;
    font-weight: 700;
    color: white;
    letter-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 2rem;
      letter-spacing: 1px;
    }
  }

  .sub-heading span {
    font-size: 6.5rem;

    @media (max-width: 768px) {
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
      line-height: 1.6;
    }
  }
`;

const StartButton = styled(motion.button)`
  display: block;
  margin: 0 auto;
  padding: 20px 40px;
  border: none;
  border-radius: 120px;
  background: ${props => props.$isAuthenticating ? '#666' : '#FA483C'};
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: ${props => props.$isAuthenticating ? 'default' : 'pointer'};
  transition: all 0.3s ease;
  opacity: ${props => props.$isAuthenticating ? 0.5 : 1};
  margin-bottom: 1rem;

  &:hover {
    transform: ${props => props.$isAuthenticating ? 'none' : 'translateY(-2px)'};
    box-shadow: ${props => props.$isAuthenticating ? 'none' : '0 4px 12px rgba(250, 72, 60, 0.3)'};
  }
`;

const ShinyLine = styled(motion.div)`
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(250, 72, 60, 0) 0%,
    rgba(250, 72, 60, 1) 50%,
    rgba(250, 72, 60, 0) 100%
  );
  margin: 5px auto 10px;
  position: relative;
  max-width: 600px;
  box-shadow: 
    0 0 20px rgba(250, 72, 60, 0.8),
    0 0 40px rgba(250, 72, 60, 0.4);

  &::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(
      90deg,
      rgba(250, 72, 60, 0) 0%,
      rgba(250, 72, 60, 0.8) 50%,
      rgba(250, 72, 60, 0) 100%
    );
    filter: blur(15px);
    opacity: 0.8;
  }
`;

const MarketingPlusLogo = styled.img`
  display: block;
  margin: 4rem auto 0;  
  width: 400px;
  max-width: 100%;
  height: auto;
`;

const IntroSection = () => {
  const [clickCount, setClickCount] = useState(0);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const clickTimeout = useRef(null);

  const handleButtonClick = () => {
    if (!isAuthenticating) {
      // If already authenticated, proceed with normal flow
      startEvent();
      return;
    }

    setClickCount(prev => prev + 1);

    // Reset click count after 1 second if no second click
    if (clickTimeout.current) clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      setClickCount(0);
    }, 1000);

    // If this is the second click within the timeout
    if (clickCount === 1) {
      Cookies.set('isAdmin', 'true', { expires: 7 });
      setIsAuthenticating(false);
      setClickCount(0);
      if (clickTimeout.current) clearTimeout(clickTimeout.current);
    }
  };

  const startEvent = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/event/start`, {
        duration: EVENT_CONFIG.submissionWindowMinutes
      });
      toast.success('Event started successfully!');
    } catch (error) {
      console.error('Failed to start event:', error);
      toast.error('Failed to start event');
    }
  };

  return (
    <IntroContainer
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <h1 className="main-heading">Exclusive UX Audit <br />
        <ShinyLine
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        />
        <div className="highlight">by <motion.img
          src={webkeyzLogo}
          alt="Webkeyz Logo"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{ marginLeft: '1rem', width: '250px' }}
        /></div>
      </h1>

      <h2 className="sub-heading"><span>{EVENT_CONFIG.maxWinners}</span> LUCKY WINNERS</h2>

      <StartButton
        onClick={handleButtonClick}
        whileHover={{ scale: isAuthenticating ? 1 : 1.05 }}
        whileTap={{ scale: isAuthenticating ? 1 : 0.98 }}
        $isAuthenticating={isAuthenticating}
      >
        Let The Fun Begin!
      </StartButton>

      <MarketingPlusLogo
        src={marketingPlusLogo}
        alt="Marketing Plus 2024 Logo"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      />
    </IntroContainer>
  );
};

export default IntroSection; 