import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { API_BASE_URL } from '../config/apiConfig';
import Cookies from 'js-cookie';

const StyledForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 769px) {
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
`;

const FormField = styled.div`
  position: relative;
  
  input {
    width: 100%;
    padding: 16px 0;
    border: none;
    border-bottom: 1px solid #424242;
    background: transparent;
    color: white;
    font-size: 1.1rem;
    transition: all 0.3s ease;

    /* Handle autofill without background color */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: white !important;
      -webkit-box-shadow: 0 0 0 30px transparent inset !important;
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
      caret-color: white;
    }

    &:focus {
      color: white !important;
      -webkit-text-fill-color: white !important;
      outline: none;
      border-bottom-color: var(--primary);
    }

    /* Remove the default placeholder */
    &::placeholder {
      color: transparent;
      opacity: 0;
    }

    &:focus + label, &:not(:placeholder-shown) + label {
      transform: translateY(-20px) scale(0.8);
      color: var(--primary);
    }
  }

  label {
    position: absolute;
    left: 0;
    top: 16px;
    color: rgba(255, 255, 255, 0.6);
    transform-origin: 0 0;
    transition: all 0.3s ease;
    pointer-events: none;
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 16px 40px;
  border: none;
  border-radius: 120px;
  background: #FA483C;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  margin-top: 20px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(250, 72, 60, 0.3);
  }
`;

const ChanceText = styled.p`
  text-align: center;
  color: white;
  font-size: 1.4rem;
  margin-bottom: 30px;
  font-weight: 500;
`;

const FormContainer = styled(motion.div)`
  position: relative;
  padding: 1rem 0;
`;

const SuccessMessage = styled(motion.div)`
  margin: 0 auto;
  text-align: center;
  color: white;
  
  h3 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  p {
    font-size: 1.3rem;
  }

  .unique-id-text {
    margin: 2rem auto 0;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .unique-id {
    font-size: 4.5rem;
    font-weight: 700;
  }
`;

const Form = () => {
  const { isAdmin } = useAuth();
  const [formData, setFormData] = useState({
    companyName: '',
    fullName: '',
    email: '',
    mobile: ''
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(() => {
    const savedSubmission = localStorage.getItem('userSubmission');
    return savedSubmission ? JSON.parse(savedSubmission) : null;
  });

  useEffect(() => {
    if (isAdmin) {
      return null;
    }
  }, [isAdmin]);

  if (isAdmin || Cookies.get('isAdmin') === 'true') {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/submissions`, formData);
      setSubmissionSuccess(response.data);
      // Store submission in localStorage with the correct property name
      localStorage.setItem('userSubmission', JSON.stringify({
        ...response.data,
        uniqueNumber: response.data.uniqueNumber // Make sure we store the correct property
      }));
      toast.success('Submission successful!');
    } catch (error) {
      console.error('Submission failed:', error);
      toast.error(error.response?.data?.message || 'Failed to submit');
    }
  };

  return (
    <FormContainer>
      {!submissionSuccess && (
        <>
          <ChanceText>Seize The Chance!</ChanceText>
          <StyledForm
            onSubmit={handleSubmit}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <FormField>
              <input
                type="text"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              />
              <label>Company Name</label>
            </FormField>

            <FormField>
              <input
                type="text"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
              />
              <label>Full Name</label>
            </FormField>

            <FormField>
              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              <label>Email</label>
            </FormField>

            <FormField>
              <input
                type="text"
                placeholder="Mobile"
                value={formData.mobile}
                onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              />
              <label>Mobile</label>
            </FormField>

            <SubmitButton
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Submit Now
            </SubmitButton>
          </StyledForm>
        </>
      )}

      {submissionSuccess && (
        <SuccessMessage
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h3>Thank You for<br />Participating!</h3>
          <p>Winners will be announced soon.<br />
            Stay tuned you might be one of our lucky winners!</p>
          <p className="unique-id-text">Your number is</p>
          <div className="unique-id">{submissionSuccess.uniqueNumber}</div>
        </SuccessMessage>
      )}
    </FormContainer>
  );
};

export default Form;
