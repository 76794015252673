import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import gsap from 'gsap';
import { socket } from '../socket';
import axios from 'axios';
import { API_BASE_URL } from '../config/apiConfig';

const BubbleContainer = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  margin: 0 auto;
  padding: 2rem 0;
  background: rgba(29, 29, 29, 0.42);
  border-radius: 30px;
  max-width: 1600px;
`;

const Bubble = styled(motion.div)`
  font-size: 2.2rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  color: white;
  border-radius: 50%;
  transform-style: preserve-3d;
  will-change: transform;
  left: 0;
  top: 0;
  transform-origin: center center;
  transition: background 0.5s ease, box-shadow 0.5s ease;

  &.new-bubble {
    background: white;
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.8);
  }

  &:not(.new-bubble) {
    background: linear-gradient(180deg, #7C0C30 0%, #FA483C 100%);
    box-shadow: 0px 0px 115px rgba(243, 26, 26, 0.38);
  }
`;

const RealTimeDisplay = () => {
  const [submissions, setSubmissions] = useState([]);
  const [lastSubmission, setLastSubmission] = useState(null);
  const containerRef = useRef(null);
  const bubblesRef = useRef({});

  const animateBubble = (bubbleEl, isNew = false) => {
    if (!bubbleEl || !containerRef.current) return;

    const container = containerRef.current.getBoundingClientRect();
    const size = 80 + Math.random() * 40;
    const padding = 40;

    // Random position within container bounds
    const randomPosition = () => ({
      x: padding + Math.random() * (container.width - size - padding * 2),
      y: padding + Math.random() * (container.height - size - padding * 2)
    });

    const startPos = randomPosition();
    
    // Set initial position and size
    gsap.set(bubbleEl, {
      width: size,
      height: size,
      x: startPos.x,
      y: startPos.y,
      opacity: 0
    });

    // Entrance animation
    gsap.to(bubbleEl, {
      duration: 0.5,
      opacity: 1,
      scale: 1,
      ease: "back.out(1.2)"
    });

    // If it's a new bubble, handle the flash effect with smooth transition
    if (isNew) {
      // Add transition class immediately for entrance
      bubbleEl.classList.add('new-bubble');
      
      // Schedule removal with a smooth transition
      setTimeout(() => {
        // The transition property in the styled component will handle the smooth change
        bubbleEl.classList.remove('new-bubble');
      }, 500); // Increased from 250ms to 500ms for a more noticeable transition
    }

    // Continuous random movement
    const moveRandomly = () => {
      const newPos = randomPosition();
      const duration = 5 + Math.random() * 10; // Random duration between 5-15 seconds

      gsap.to(bubbleEl, {
        duration,
        x: newPos.x,
        y: newPos.y,
        ease: "none",
        onComplete: moveRandomly
      });
    };

    moveRandomly();

    // Add subtle rotation
    gsap.to(bubbleEl, {
      duration: 10 + Math.random() * 5,
      rotation: 360,
      repeat: -1,
      ease: "none"
    });

    // Add subtle scale animation
    gsap.to(bubbleEl, {
      duration: 2 + Math.random() * 2,
      scale: 0.9 + Math.random() * 0.2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut"
    });
  };

  // Separate useEffect for socket events
  useEffect(() => {
    const handleNewSubmission = (submission) => {
      console.log('New submission received:', submission);
      setSubmissions(prev => {
        // Check if submission already exists
        const exists = prev.some(sub => sub._id === submission._id);
        if (exists) return prev;
        return [...prev, submission];
      });
      setLastSubmission(submission._id);
    };

    // Initial fetch
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/submissions`);
        console.log('Fetched submissions:', response.data);
        setSubmissions(response.data);
      } catch (error) {
        console.error('Failed to fetch submissions:', error);
      }
    };

    socket.on('newSubmission', handleNewSubmission);
    fetchSubmissions();

    return () => {
      socket.off('newSubmission', handleNewSubmission);
      // Cleanup GSAP animations
      Object.values(bubblesRef.current).forEach(bubble => {
        if (bubble) {
          gsap.killTweensOf(bubble);
        }
      });
    };
  }, []); // Empty dependency array

  // Handle new bubble references and start their animations
  useEffect(() => {
    submissions.forEach(submission => {
      const bubbleEl = bubblesRef.current[submission._id];
      if (bubbleEl && !bubbleEl.hasAttribute('data-animated')) {
        bubbleEl.setAttribute('data-animated', 'true');
        animateBubble(bubbleEl, submission._id === lastSubmission);
      }
    });
  }, [submissions, lastSubmission]);

  return (
    <BubbleContainer ref={containerRef}>
      <AnimatePresence>
        {submissions.map((submission) => (
          <Bubble
            key={submission._id}
            ref={el => bubblesRef.current[submission._id] = el}
            className={submission._id === lastSubmission ? 'new-bubble' : ''}
            initial={false}
            animate={{ scale: 1 }}
            exit={{ scale: 0, opacity: 0 }}
          >
            {submission.uniqueNumber}
          </Bubble>
        ))}
      </AnimatePresence>
    </BubbleContainer>
  );
};

export default RealTimeDisplay;
