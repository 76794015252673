import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import webkeyzLogo from '../assets/img/webkeyz-logo.svg';

const LogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding: 3.5rem 0 1rem;
  
  img {
    width: 250px;
    height: auto;
  }
`;

const WebkeyzLogo = () => (
  <LogoContainer
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <img src={webkeyzLogo} alt="Webkeyz Logo" />
  </LogoContainer>
);

export default WebkeyzLogo; 