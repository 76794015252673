import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import qrCodePng from '../assets/img/qrcode.png';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { API_BASE_URL } from '../config/apiConfig';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const TimerContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 2rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const QRCode = styled.div`
  width: 300px;
  height: 300px;
  background: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CountdownSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TimeDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .number {
    font-size: 7.5rem;
    font-weight: 700;
    color: white;
  }

  .label {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 5px;
  }
`;

const Separator = styled.div`
  font-size: 6rem;
  font-weight: 700;
  color: white;
  margin-top: -10px;
`;

const InfoText = styled.div`
padding-top: 1rem;
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    display: none;
  }

  .instruction {
    font-size: 2.5rem;
    font-weight: 500;
    color: white;
  }

  .url {
    font-size: 2.4rem;
    font-weight: 500;
    color: white;
  }
`;

const LoadingDisplay = styled(motion.div)`
  font-size: 7.5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ConfirmationPopup = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background: rgba(29, 29, 29, 0.95);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: center;
  border: 2px solid rgba(250, 72, 60, 0.3);
  max-width: 400px;
  width: 90%;
`;

const PopupButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
`;

const PopupButton = styled(motion.button)`
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;

  &.cancel {
    background: var(--primary);
    color: white;
  }

  &.resume {
    background: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
  }
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const CountdownTimer = ({ timeRemaining }) => {
  const { isAdmin } = useAuth();
  const isAdminUser = isAdmin || Cookies.get('isAdmin') === 'true';
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isLoading, setIsLoading] = useState(true);
  const [displayTime, setDisplayTime] = useState({ minutes: '--', seconds: '--' });
  const [showPopup, setShowPopup] = useState(false);
  const clickTimeoutRef = useRef(null);
  const clickCountRef = useRef(0);

  useEffect(() => {
    if (timeRemaining > 0) {
      setIsLoading(false);
      setDisplayTime({
        minutes: String(Math.floor(timeRemaining / 60000)).padStart(2, '0'),
        seconds: String(Math.floor((timeRemaining % 60000) / 1000)).padStart(2, '0')
      });
    }
  }, [timeRemaining]);

  const handleTimerClick = () => {
    if (!isAdminUser) return;

    clickCountRef.current += 1;

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    if (clickCountRef.current === 2) {
      setShowPopup(true);
      clickCountRef.current = 0;
    } else {
      clickTimeoutRef.current = setTimeout(() => {
        clickCountRef.current = 0;
      }, 300); // Reset after 300ms if no second click
    }
  };

  const handleStopTimer = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/event/stop`);
      setShowPopup(false);
      toast.success('Timer stopped successfully');
    } catch (error) {
      console.error('Failed to stop timer:', error);
      toast.error('Failed to stop timer');
      setShowPopup(false);
    }
  };

  return (
    <>
      <TimerContainer
        onClick={handleTimerClick}
        style={{ cursor: isAdminUser ? 'pointer' : 'default' }}
      >
        {!isMobile && <QRCode><img src={qrCodePng} alt="QR Code" /></QRCode>}

        <CountdownSection>
          <TimeDisplay>
            {isLoading ? (
              <LoadingDisplay
                animate={{ opacity: [0.3, 0.6, 0.3] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                --:--
              </LoadingDisplay>
            ) : (
              <>
                <TimeUnit>
                  <motion.div 
                    className="number"
                    key={displayTime.minutes}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {displayTime.minutes}
                  </motion.div>
                </TimeUnit>

                <Separator>:</Separator>

                <TimeUnit>
                  <motion.div 
                    className="number"
                    key={displayTime.seconds}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {displayTime.seconds}
                  </motion.div>
                </TimeUnit>
              </>
            )}
          </TimeDisplay>

          {!isMobile && (
            <InfoText>
              <div className="instruction">Scan or visit:</div>
              <a href="https://marketingplus2024.webkeyz.com" className="url" style={{ textDecoration: 'none', color: 'inherit' }}>https://marketingplus2024.webkeyz.com</a>
            </InfoText>
          )}
        </CountdownSection>

        {!isMobile && <QRCode><img src={qrCodePng} alt="QR Code" /></QRCode>}
      </TimerContainer>

      <AnimatePresence>
        {showPopup && (
          <>
            <Overlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <ConfirmationPopup
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <h3 style={{ marginTop: 0 }}>Stop Timer?</h3>
              <p>Are you sure you want to end the timer before its scheduled time?</p>
              <PopupButtons>
                <PopupButton
                  className="cancel"
                  onClick={handleStopTimer}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Stop Timer
                </PopupButton>
                <PopupButton
                  className="resume"
                  onClick={() => setShowPopup(false)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Resume
                </PopupButton>
              </PopupButtons>
            </ConfirmationPopup>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default CountdownTimer; 