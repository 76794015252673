import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import confetti from 'canvas-confetti';
import { toast } from 'react-toastify';
import gsap from 'gsap';
import WebkeyzLogo from './WebkeyzLogo';

const WinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 1rem 0;
`;

const Title = styled(motion.h1)`
margin-top: 0;
  font-size: 5rem;
  font-weight: 900;
  text-align: center;
  background: linear-gradient(135deg, #fff 0%, #ccc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 30px rgba(255, 255, 255, 0.1);
  margin-bottom: 2rem;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: clamp(2rem, 8vw, 3rem);
  }

  span {
    display: inline-block;
    color: var(--primary);
    -webkit-text-fill-color: var(--primary);
  }
`;

const WinnerButton = styled(motion.button)`
  padding: 40px 80px;
    font-size: 2rem;
    font-weight: bold;
    border: none;
    border-radius: 120px;
    background: #FA483C;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

  &:hover {
        transform: translateY(-3px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CardsContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  perspective: 1000px;
`;

const WinnerBubble = styled(motion.div)`
  border: 1px solid #940000;
  background: linear-gradient(180deg, #7C0C30 0%, #FA483C 100%);
  box-shadow: 0px 0px 115px 31px rgba(243, 26, 26, 0.38);
  backdrop-filter: blur(12px);
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  color: white;
  text-align: center;
  padding: 20px;

  .company-name {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 10px;
    background: linear-gradient(135deg, #fff, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .winner-name {
    font-size: 1.4rem;
    opacity: 0.9;
    margin-bottom: 20px;
  }

  .number {
    font-size: 3.5rem;
    font-weight: bold;
    color: white;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const WinnerAnnouncement = styled(motion.div)`
  width: 400px;
  height: 250px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 3rem;
  color: white;
  text-align: center;
  background: rgba(29, 29, 29, 0.95);
  border-radius: 30px;
  border: 2px solid rgba(250, 72, 60, 0.3);
  box-shadow: 0 0 50px rgba(250, 72, 60, 0.15);
  box-sizing: border-box;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(250, 72, 60, 0.3) 50%,
      transparent 100%
    );
  }
`;

const NumberSpinner = styled(motion.div)`
  width: 100%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem 0;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(250, 72, 60, 0.15) 0%, transparent 70%);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;

const SpinnerNumber = styled(motion.div)`
  font-size: 9rem;
  font-weight: 900;
  font-family: 'Monaco', monospace;
  position: relative;
  color: white;
  padding: 0 2rem;
  
  .number {
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 0%, #FA483C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 0 20px rgba(250, 72, 60, 0.5));
  }

  .strike-through {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(250, 72, 60, 0.8) 20%,
      #FA483C 50%,
      rgba(250, 72, 60, 0.8) 80%,
      transparent 100%
    );
    box-shadow: 
      0 0 20px rgba(250, 72, 60, 0.8),
      0 0 40px rgba(250, 72, 60, 0.4);
    transform: translateY(-50%);
    transform-origin: left;
    scale: 0 1;  // Hidden by default
  }
`;

const WinnerSelection = ({ submissions, maxWinners, winners, isAdminProp }) => {
    // Function to normalize company names
    const normalizeCompanyName = (name) => {
        return name
            .toLowerCase()                // Convert to lowercase
            .replace(/\s+/g, '')         // Remove all whitespace
            .trim();                     // Trim any remaining whitespace
    };

    // Function declarations first
    const getAvailableSubmissions = useCallback((allSubmissions, currentWinners) => {
        // Get all winning companies with normalized names
        const winningCompanies = new Set(
            (currentWinners?.map(w => normalizeCompanyName(w.companyName)) || [])
        );
        
        return (allSubmissions || []).filter(sub => {
            const normalizedCompanyName = normalizeCompanyName(sub.companyName);
            
            // Check for Webkeyz variations
            const isWebkeyz = normalizedCompanyName.includes('webkeyz');
            
            // Check if company has already won
            const companyAlreadyWon = winningCompanies.has(normalizedCompanyName);
            
            return !companyAlreadyWon && !isWebkeyz;
        });
    }, []); // Empty dependency array since this function doesn't depend on any props or state

    // State initialization
    const [selectedWinners, setSelectedWinners] = useState(winners || []);
    const [isSelecting, setIsSelecting] = useState(false);
    const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
    const [showWinnerCards, setShowWinnerCards] = useState(false);
    const [currentWinnerNumber, setCurrentWinnerNumber] = useState(null);
    const [availableSubmissions, setAvailableSubmissions] = useState([]);
    const [userSubmission] = useState(() => {
        const saved = localStorage.getItem('userSubmission');
        return saved ? JSON.parse(saved) : null;
    });

    // Update available submissions when submissions or selectedWinners change
    useEffect(() => {
        if (submissions?.length > 0) {
            const newAvailableSubmissions = getAvailableSubmissions(submissions, selectedWinners);
            setAvailableSubmissions(newAvailableSubmissions);
            console.log('Updated available submissions:', newAvailableSubmissions.length);
        }
    }, [submissions, selectedWinners, getAvailableSubmissions]);

    const selectWinner = useCallback(async () => {
        if (isSelecting || !availableSubmissions?.length) {
            console.log('Cannot select winner:', { 
                isSelecting, 
                availableSubmissionsLength: availableSubmissions?.length,
                submissions: submissions?.length
            });
            return;
        }

        setIsSelecting(true);

        try {
            // Animation setup
            gsap.to(".winner-announcement", {
                boxShadow: "0 0 80px rgba(250, 72, 60, 0.3)",
                duration: 1.5,
                repeat: -1,
                yoyo: true,
                ease: "sine.inOut"
            });

            let frame = 0;
            const totalFrames = 120;
            const animate = () => {
                if (frame < totalFrames) {
                    const progress = frame / totalFrames;
                    
                    let speed;
                    if (progress < 0.3) speed = 30;
                    else if (progress < 0.6) speed = 60;
                    else if (progress < 0.8) speed = 100;
                    else speed = 150;

                    // During animation, show all numbers (including excluded ones)
                    let randomNum;
                    if (progress > 0.8) {
                        // In final phase, only show numbers from valid candidates
                        const validSubmission = availableSubmissions[
                            Math.floor(Math.random() * availableSubmissions.length)
                        ];
                        randomNum = validSubmission.uniqueNumber;
                    } else {
                        // During animation, use all submissions for display
                        const allSubmission = submissions[
                            Math.floor(Math.random() * submissions.length)
                        ];
                        randomNum = allSubmission.uniqueNumber;
                    }

                    setCurrentWinnerNumber(String(randomNum).padStart(3, '0'));
                    frame++;
                    setTimeout(animate, speed);
                } else {
                    gsap.to(".winner-announcement", {
                        boxShadow: "0 0 50px rgba(250, 72, 60, 0.15)",
                        duration: 0.5
                    });
                    
                    // Select final winner from available submissions
                    const winnerIndex = Math.floor(Math.random() * availableSubmissions.length);
                    const winner = availableSubmissions[winnerIndex];
                    
                    setCurrentWinnerNumber(String(winner.uniqueNumber).padStart(3, '0'));
                    
                    setTimeout(() => {
                        setSelectedWinners(prev => [...prev, winner]);
                        setCurrentWinnerIndex(prev => prev + 1);

                        confetti({
                            particleCount: 100,
                            spread: 70,
                            origin: { y: 0.6 },
                            colors: ['#fa483c', '#2a3169', '#000']
                        });

                        setTimeout(() => {
                            setCurrentWinnerNumber(null);
                            setIsSelecting(false);
                        }, 2000);
                    }, 2000);
                }
            };

            animate();

        } catch (error) {
            console.error('Failed to select winner:', error);
            toast.error('Failed to select winner');
            setIsSelecting(false);
        }
    }, [isSelecting, availableSubmissions, submissions]);

    const revealWinnerCards = () => {
        setShowWinnerCards(true);
        confetti({
            particleCount: 200,
            spread: 100,
            origin: { y: 0.6 },
            colors: ['#fa483c', '#2a3169', '#000']
        });
    };

    // Debug logging
    useEffect(() => {
        console.log('Component state:', {
            submissionsLength: submissions?.length,
            availableSubmissionsLength: availableSubmissions?.length,
            isSelecting,
            currentWinnerIndex
        });
    }, [submissions, availableSubmissions, isSelecting, currentWinnerIndex]);

    return (
        <WinnerContainer>
            <WebkeyzLogo />
            <Title
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
            >
                {!showWinnerCards ? (
                    <>Time to Select Our <span>Lucky Winners!</span></>
                ) : (
                    <>Our <span>Lucky Winners!</span></>
                )}
            </Title>

            {userSubmission && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    style={{
                        background: 'rgba(29, 29, 29, 0.95)',
                        padding: '2rem 3rem',
                        boxShadow: '0 0 50px rgba(250, 72, 60, 0.15)',
                        border: '2px solid rgba(250, 72, 60, 0.3)',
                        borderRadius: '15px',
                        marginBottom: '1rem',
                        textAlign: 'center'
                    }}
                >
                    <div style={{ fontSize: '1.5rem', fontWeight: '500', marginBottom: '0.5rem' }}>Your number is</div>
                    <div style={{ 
                        fontSize: '4.5rem', 
                        fontWeight: '700',
                        color: 'var(--primary)'
                    }}>
                        {userSubmission.uniqueNumber}
                    </div>
                </motion.div>
            )}

            {isAdminProp && (
                <ButtonContainer>
                    {currentWinnerIndex < maxWinners && (
                        <WinnerButton
                            onClick={selectWinner}
                            disabled={isSelecting}
                        >
                            Select Winner {currentWinnerIndex + 1}
                        </WinnerButton>
                    )}
                    {currentWinnerIndex === maxWinners && !showWinnerCards && (
                        <WinnerButton onClick={revealWinnerCards}>
                            Reveal Winners
                        </WinnerButton>
                    )}
                </ButtonContainer>
            )}

            {!showWinnerCards ? (
                <>
                    {currentWinnerNumber && (
                        <WinnerAnnouncement
                            className="winner-announcement"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                        >
                            {isSelecting ? (
                                <motion.div
                                    key="selecting"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                                >
                                    <motion.span
                                        style={{ fontSize: '2rem', marginBottom: '1rem' }}
                                        animate={{
                                            opacity: [1, 0.5, 1],
                                            transition: { duration: 2, repeat: Infinity }
                                        }}
                                    >
                                    </motion.span>
                                    <NumberSpinner>
                                        <SpinnerNumber
                                            key={currentWinnerNumber}
                                            initial={{ opacity: 0, scale: 0.9 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.9 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 200,
                                                damping: 20
                                            }}
                                        >
                                            <motion.div 
                                                className="number"
                                                animate={{
                                                    filter: isSelecting 
                                                        ? ["drop-shadow(0 0 20px rgba(250, 72, 60, 0.5))", "drop-shadow(0 0 30px rgba(250, 72, 60, 0.8))"] 
                                                        : "drop-shadow(0 0 20px rgba(250, 72, 60, 0.5))"
                                                }}
                                                transition={{
                                                    duration: 0.5,
                                                    repeat: isSelecting ? Infinity : 0,
                                                    repeatType: "reverse"
                                                }}
                                            >
                                                {currentWinnerNumber}
                                            </motion.div>
                                            <motion.div 
                                                className="strike-through"
                                                initial={{ scaleX: 0 }}
                                                animate={{ 
                                                    scaleX: !isSelecting && currentWinnerNumber ? 1 : 0 
                                                }}
                                                transition={{
                                                    duration: 0.8,
                                                    ease: "easeOut",
                                                    delay: 0.2
                                                }}
                                            />
                                        </SpinnerNumber>
                                    </NumberSpinner>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="winner"
                                    style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: 1,
                                        transition: { duration: 0.5 }
                                    }}
                                    exit={{ opacity: 0 }}
                                >
                                    <motion.div
                                        initial={{ scale: 0.5, opacity: 0 }}
                                        animate={{
                                            scale: 1,
                                            opacity: 1,
                                            transition: { duration: 0.5, delay: 0.2 }
                                        }}
                                    >
                                        Winner {currentWinnerIndex}:
                                        <motion.div
                                            style={{ fontSize: '7rem', marginTop: '1rem' }}
                                            initial={{ scale: 0.5 }}
                                            animate={{
                                                scale: [0.5, 1.2, 1],
                                                rotate: [0, -10, 10, 0]
                                            }}
                                            transition={{ duration: 0.8, ease: "easeOut" }}
                                        >
                                            {currentWinnerNumber}!
                                            <motion.span
                                                animate={{
                                                    rotate: [0, 20, -20, 0],
                                                    scale: [1, 1.2, 1]
                                                }}
                                                transition={{ duration: 0.5, delay: 0.3 }}
                                            >
                                                🎉
                                            </motion.span>
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            )}
                        </WinnerAnnouncement>
                    )}
                </>
            ) : (
                <CardsContainer>
                    <AnimatePresence>
                        {selectedWinners.map((winner, index) => (
                            <WinnerBubble
                                key={winner._id}
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    duration: 1,
                                    delay: index * 0.5,
                                    type: "spring",
                                    damping: 20
                                }}
                            >
                                <div className="company-name">{winner.companyName}</div>
                                <div className="winner-name">{winner.fullName}</div>
                                <div className="number">{winner.uniqueNumber}</div>
                            </WinnerBubble>
                        ))}
                    </AnimatePresence>
                </CardsContainer>
            )}
        </WinnerContainer>
    );
};

export default WinnerSelection;
